import { get, post } from "./api-client";

import type { ShippingOptionsResponse } from '@/types/models/order/shipping-options-response';
import type { ShippingFormViewModel } from "@/types/models/order/shipping-form-view-model";
import type { ShippingOptionFormViewModel } from "@/types/models/order/shipping-option-form-view-model";
import type { OrderAddressModel } from "@/types/models/order/order-addres-model";
import type { OrderConfirmationFormViewModel } from "@/types/models/order/order-confirmation-form-view-model";
import type { OrderPaymentFormViewModel } from "@/types/models/order/order-payment-form-view-model";
import type { CustomerFormViewModel } from "@/types/models/customer/customer-form-view-model";
import type { StripeChargeResponse } from "@/types/models/stripe/stripe-charge-response";
import type { StripeDataModel } from "@/types/models/stripe/stripe-data-model";
import type { OrderViewModel } from "@/types/models/order/order-view-model";
import type { OrderInitFormViewModel } from "@/types/models/order/order-init-form-view-model";
import type { SelectListOption } from "@/types/models/select-list-item";
import type { OrderAddressEditFormViewModel } from "@/types/models/address/order-address-edit-form-view-model";
import type { OrderSaveViewModel } from "@/types/models/order/order-save-quantity-view-model";
import type { BlobUploadViewModel } from "@/types/models/order/blob-upload-view-model";
import type { DeleteCartItemViewModel, EditCartViewModel } from "@/types/models/cart/cart-item-view-model";
import type { OrderProfileViewModel } from "@/types/models/order/order-profile-view-model";
import type { SaveAdditionalInfoViewModel } from "@/types/models/order/save-additional-info-view-model";

export default {
    createOrder: async (data: OrderInitFormViewModel) => post('/order/createOrder', data),

    saveOrderBlobUrl: async (model: BlobUploadViewModel) => post('/order/saveOrderBlobUrl', model),

    syncShoppingCartItems: async () => post('/order/syncShoppingCartItems'),

    getSecureCustomer: async () => get('/order/getSecureCustomer'),

    resetOrderBlobUrl: async () => post('/order/resetOrderBlobUrl'),

    getSecureOrders: async () => get<OrderProfileViewModel[]>('/order/getSecureOrders'),

    getOrder: async () => get<OrderViewModel>('/order/getCurrentOrder'),

    getSecureOrder: async (id: number) => get<OrderViewModel>('/order/getSecureOrder', { params: { id } }),

    createCountryData: async () => {
        let countries: SelectListOption[];
        countries = await get<SelectListOption[]>('/order/getCountries');
        countries.sort((x, y) => { return x.text === 'Canada' ? -1 : y.text === 'Canada' ? 1 : 0; });
        countries.sort((x, y) => { return x.text === 'United States' ? -1 : y.text === 'United States' ? 1 : 0; });
        // @ts-ignore
        countries.splice(2, 0, { text: 'Other Countries', value: '', disabled: true });

        return countries;
    },

    createStateData: async (id: number) => get('/order/getCountryStates', { params: { id } }),

    editProductInOrderAndCart: async (model: EditCartViewModel) => post('/order/editProductInOrderAndCart', model),

    removeProductFromOrderAndCart: async (model: DeleteCartItemViewModel) => post('/order/removeProductFromOrderAndCart', model),

    addCustomerToOrder: async (data: CustomerFormViewModel) => post('/order/addCustomerToOrder', data),

    getCustomer: async () => get<CustomerFormViewModel>('/order/getCustomer'),

    getCustomerCompanyName: async (customerId: number) => get<string>('/order/getCustomerCompanyName', { params: { customerId } }),

    addStepToOrder: async (stepId: number) => post('/order/AddStepToOrder/' + stepId),

    addAddressToOrder: async (data: OrderAddressModel) => post('/order/addAddressToOrder', data),

    createShippingData: async (data: ShippingFormViewModel) => post('/order/createShippingData', data),

    getShippingOptions: async (isStore = false, freezeProtection = false, liftgate = false) => get<ShippingOptionsResponse>('/order/getShippingOptions', { params: { isStore: isStore, freezeProtection: freezeProtection, liftgate: liftgate } }),

    estimateShipping: async (model: ShippingFormViewModel) => post<ShippingOptionsResponse>('/order/estimateShipping', model),

    addShippingOptionToOrder: async (data: ShippingOptionFormViewModel) => post('/order/addShippingOptionToOrder', data),

    getStripePaymentData: async () => get<StripeDataModel>('/order/getStripePaymentData'),

    chargeUsingStripe: async (token: string) => get<StripeChargeResponse>('/order/chargeUsingStripe', { params: { token } }),

    saveOrderPayment: async (data: OrderPaymentFormViewModel) => post('/order/saveOrderPayment', data),

    getOrderSalesTaxRate: async () => get<number>('/order/getOrderSalesTaxRate'),

    stateIsTaxable: async (stateId: number) => get<boolean>('/order/stateIsTaxable', { params: { stateId } }),

    confirmOrder: async (data: OrderConfirmationFormViewModel) => post('/order/confirmOrder', data),

    saveOrUpdateOrder: async (isAdminPanel = true, orderNote = '') => post('/order/saveOrUpdateOrder', { isAdminPanel: isAdminPanel, note: orderNote } as OrderSaveViewModel),

    editOrderAddress: async (data: OrderAddressEditFormViewModel) => post('/order/editOrderAddress', data),

    addShipFromAddressToOrder: async (warehouseAddressId: number) => get('/order/addShipFromAddressToOrder', { params: { warehouseAddressId } }),

    saveAdditionalInfo: async (data: SaveAdditionalInfoViewModel) => post('/order/saveAdditionalInfo', data),

    getLatestOrderId: async (customerId: number, billingEmail: string) => get<number | null>('/order/getLatestOrderId', { params: { customerId, billingEmail } })
};